@keyframes fadeInDown {
    0% {
       opacity: 0;
       transform: translateY(-20px);
    }
    100% {
       opacity: 1;
       transform: translateY(0);
    }
 } 


 @keyframes fadeOutUp {
    0% {
       opacity: 1;
       transform: translateY(0);
    }
    100% {
       opacity: 0;
       transform: translateY(-20px);
    }
 } 

 .ant-select-selector {
   border-radius: 8px !important;
}